import React, { useState } from "react";
import { Link } from "gatsby";
import { Form, Button, Dropdown } from "react-bootstrap";
import Select from 'react-select'
import "./ContactForm.scss";
import { useLocation } from "@reach/router";
import ContactForm from "../forms/contact";
const Contact = () => {

  const location = useLocation();
  let add_extra_emails = ""; // for why mccone properties page
  if(location && location.pathname.includes('why-mccone-properties')) {
    add_extra_emails = "customerservice@mcconeproperties.com, mccone-allemails@starberry.tv, info@mcconeproperties.com, hassan.r@mcconeproperties.com, furqan.b@mcconeproperties.com";
  }

  const department = [
    { value: 'Sales ', label: 'Sales ' },
    { value: 'Product', label: 'Product' },
    { value: 'Sales', label: 'Sales' },
  ]
  const value = [
    { value: 'I’m looking to buy', label: 'I’m looking to buy' },
    { value: 'I’m looking to sell', label: 'I’m looking to sell' },
    { value: 'I’m looking to buy', label: 'I’m looking to buy' }
  ]
  
  
  
  return (
    <>
      <div className="form-wraps">
        <ContactForm to_email_id={add_extra_emails}/>
      </div>
    </>
  );
};
export default Contact;
