import * as React from "react";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header/Header";
import ContactForm from "../../components/ContactPageForm/Form";
import Footer from "../../components/Footer/Footer";

import "./ContactPage.scss";

const Contact = () => {
  return (
    <>
      <div className="contact-page">
        <Header />
        <Footer />
      </div>
    </>
  );
};
export default Contact;
